

























import { Component, Mixins, Prop, Model, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Popup, Cell, CellGroup, Row } from "vant";

@Component({
  components: {
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
  },
})
export default class Seen extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public height!: string; //(订单金额类型为2)
  @Prop({
    type: String,
    default: "立即购买",
  })
  public title!: string; //(订单金额类型为2)
  @Model("close", {
    type: Boolean,
  })
  readonly showPopup!: boolean;
  isShow = false;
  @Watch("showPopup", { immediate: true })
  handler(): void {
    this.isShow = this.showPopup;
  }

  close(): void {
    this.isShow = false;
    this.$emit("close");
  }
}
