




import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import FlashSaleDemo from "@/views/LimitActivities/ActivityDemo/FlashSaleDemo.vue";
@Component({
  components: {
    FlashSaleDemo,
  },
})
export default class FlashSale extends Mixins(Mixin) {
  mounted(): void {
    let RedEnvelope = this.$refs["flash-sale-demo"] as FlashSaleDemo;
    if (RedEnvelope) {
      RedEnvelope.updateData();
    }
  }
}
