import { render, staticRenderFns } from "./LimitSwiper.vue?vue&type=template&id=60701888&scoped=true&"
import script from "./LimitSwiper.vue?vue&type=script&lang=ts&"
export * from "./LimitSwiper.vue?vue&type=script&lang=ts&"
import style0 from "./LimitSwiper.vue?vue&type=style&index=0&id=60701888&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60701888",
  null
  
)

export default component.exports