













import { Component, Mixins, Prop } from "vue-property-decorator";
import { NavBar, Swipe, SwipeItem, Image, Row, CountDown } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [CountDown.name]: CountDown,
    [Row.name]: Row,
  },
})
export default class LimitNull extends Mixins(Mixin) {}
